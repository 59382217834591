import Vue from 'vue'
import Router from 'vue-router'

import Contact from './../components/Contact.vue'
import sketches from './../components/sketches'
import notes from './../components/notes'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        // Home
        { name: 'home', path: '/', component: sketches.OBJLoader },

        // Contact
        { name: 'contact', path: '/contact', component: Contact },

        // Sketches
        { name: sketches.GeodesicDome.name, path: '/sketches/' + sketches.GeodesicDome.name, component: sketches.GeodesicDome },
        { name: sketches.CanvasTextureLabels.name, path: '/sketches/' + sketches.CanvasTextureLabels.name, component: sketches.CanvasTextureLabels },
        { name: sketches.SpriteLabels.name, path: '/sketches/' + sketches.SpriteLabels.name, component: sketches.SpriteLabels },
        { name: sketches.MeshSelection.name, path: '/sketches/' + sketches.MeshSelection.name, component: sketches.MeshSelection },
        { name: sketches.EdgeShader.name, path: '/sketches/' + sketches.EdgeShader.name, component: sketches.EdgeShader },
        { name: sketches.PolkadotJSRPC.name, path: '/sketches/' + sketches.PolkadotJSRPC.name, component: sketches.PolkadotJSRPC },
        { name: sketches.YouTubeMesh.name, path: '/sketches/' + sketches.YouTubeMesh.name, component: sketches.YouTubeMesh },
        { name: sketches.VisualizeGCode.name, path: '/sketches/' + sketches.VisualizeGCode.name, component: sketches.VisualizeGCode },
        { name: sketches.Facade.name, path: '/sketches/' + sketches.Facade.name, component: sketches.Facade },
        { name: sketches.TopoLoader.name, path: '/sketches/' + sketches.TopoLoader.name, component: sketches.TopoLoader },
        { name: sketches.HexGrid.name, path: '/sketches/' + sketches.HexGrid.name, component: sketches.HexGrid },
        { name: sketches.MetaMaskAuth.name, path: '/sketches/' + sketches.MetaMaskAuth.name, component: sketches.MetaMaskAuth },
        { name: sketches.GifGenerator.name, path: '/sketches/' + sketches.GifGenerator.name, component: sketches.GifGenerator },
        { name: sketches.FlockingParticles.name, path: '/sketches/' + sketches.FlockingParticles.name, component: sketches.FlockingParticles },
        //{ name: sketches.VisualProgramming.name, path: '/sketches/' + sketches.VisualProgramming.name, component: sketches.VisualProgramming },
        { name: sketches.DragAndDrop.name, path: '/sketches/' + sketches.DragAndDrop.name, component: sketches.DragAndDrop },
        { name: sketches.AuthenticationSample.name, path: '/sketches/' + sketches.AuthenticationSample.name, component: sketches.AuthenticationSample },
        { name: sketches.BoundingBoxTool.name, path: '/sketches/' + sketches.BoundingBoxTool.name, component: sketches.BoundingBoxTool },
        { name: sketches.RoGAN.name, path: '/sketches/' + sketches.RoGAN.name, component: sketches.RoGAN },
        { name: sketches.TensorFlowObjectDetection.name, path: '/sketches/' + sketches.TensorFlowObjectDetection.name, component: sketches.TensorFlowObjectDetection },
        { name: sketches.Formula1RaceStats.name, path: '/sketches/' + sketches.Formula1RaceStats.name, component: sketches.Formula1RaceStats },
        { name: sketches.SVGLoader.name, path: '/sketches/' + sketches.SVGLoader.name, component: sketches.SVGLoader },
        { name: sketches.ActivationFunctions.name, path: '/sketches/' + sketches.ActivationFunctions.name, component: sketches.ActivationFunctions },
        { name: sketches.ScatterPlot.name, path: '/sketches/' + sketches.ScatterPlot.name, component: sketches.ScatterPlot },
        { name: sketches.LinePlot.name ,path: '/sketches/' + sketches.LinePlot.name, component: sketches.LinePlot },
        //{ name: sketches.TensorFlowJS.name, path: '/sketches/' + sketches.TensorFlowJS.name, component: sketches.TensorFlowJS },
        { name: sketches.ParticlesTemplate.name, path: '/sketches/' + sketches.ParticlesTemplate.name, component: sketches.ParticlesTemplate },
        { name: sketches.GLSLSample.name, path: '/sketches/' + sketches.GLSLSample.name, component: sketches.GLSLSample },
        { name: sketches.OBJLoader.name, path: '/sketches/' + sketches.OBJLoader.name, component: sketches.OBJLoader },
        { name: sketches.PointSample.name, path: '/sketches/' + sketches.PointSample.name, component: sketches.PointSample },
        { name: sketches.MaterialSample.name, path: '/sketches/' + sketches.MaterialSample.name, component: sketches.MaterialSample },
        { name: sketches.ColorSample.name, path: '/sketches/' + sketches.ColorSample.name, component: sketches.ColorSample },
        { name: sketches.SliderSample.name, path: '/sketches/' + sketches.SliderSample.name, component: sketches.SliderSample },
        { name: sketches.SceneTemplate.name, path: '/sketches/' + sketches.SceneTemplate.name, component: sketches.SceneTemplate },

        // Notes
        { name: notes.LightShadeShadow.name, path: '/notes/' + notes.LightShadeShadow.name, component: notes.LightShadeShadow },
        { name: notes.ConstraintProcessing.name, path: '/notes/' + notes.ConstraintProcessing.name, component: notes.ConstraintProcessing },
        { name: notes.LeetCode.name, path: '/notes/' + notes.LeetCode.name, component: notes.LeetCode },
        { name: notes.GoProgrammingReference.name, path: '/notes/' + notes.GoProgrammingReference.name, component: notes.GoProgrammingReference },
        { name: notes.WebAppDeploy.name, path: '/notes/' + notes.WebAppDeploy.name, component: notes.WebAppDeploy },
        { name: notes.SignAndVerifyEth.name, path: '/notes/' + notes.SignAndVerifyEth.name, component: notes.SignAndVerifyEth },
        { name: notes.CloudFlareDomainSetup.name, path: '/notes/' + notes.CloudFlareDomainSetup.name, component: notes.CloudFlareDomainSetup },
        { name: notes.InstallingSubstrateMacOS.name, path: '/notes/' + notes.InstallingSubstrateMacOS.name, component: notes.InstallingSubstrateMacOS },
        { name: notes.VSCodeFileRecovery.name, path: '/notes/' + notes.VSCodeFileRecovery.name, component: notes.VSCodeFileRecovery },
        { name: notes.Ethernaut.name, path: '/notes/' + notes.Ethernaut.name, component: notes.Ethernaut },
        { name: notes.ContractSecurity.name, path: '/notes/' + notes.ContractSecurity.name, component: notes.ContractSecurity },
        { name: notes.EthereumVirtualMachine.name, path: '/notes/' + notes.EthereumVirtualMachine.name, component: notes.EthereumVirtualMachine },
        { name: notes.BitcoinMining.name, path: '/notes/' + notes.BitcoinMining.name, component: notes.BitcoinMining },
        { name: notes.Kali.name, path: '/notes/' + notes.Kali.name, component: notes.Kali },
        { name: notes.LinuxFundamentals.name, path: '/notes/' + notes.LinuxFundamentals.name, component: notes.LinuxFundamentals },
        { name: notes.ForgeAuthentication.name, path: '/notes/' + notes.ForgeAuthentication.name, component: notes.ForgeAuthentication },
        { name: notes.Pix2PixComma10k.name, path: '/notes/' + notes.Pix2PixComma10k.name, component: notes.Pix2PixComma10k },
        { name: notes.CPUvsGPU.name, path: '/notes/' + notes.CPUvsGPU.name, component: notes.CPUvsGPU },
        { name: notes.TensorFlowGPU.name, path: '/notes/' + notes.TensorFlowGPU.name, component: notes.TensorFlowGPU },
        { name: notes.OpenCVCpp.name, path: '/notes/' + notes.OpenCVCpp.name, component: notes.OpenCVCpp },
        { name: notes.SelfDrivingNotes.name, path: '/notes/' + notes.SelfDrivingNotes.name, component: notes.SelfDrivingNotes },
        { name: notes.SelfDrivingVocab.name, path: '/notes/' + notes.SelfDrivingVocab.name, component: notes.SelfDrivingVocab },
        /*{ name: notes.BigO.name, path: '/notes/' + notes.BigO.name, component: notes.BigO },*/
        { name: notes.MarkdownTemplate.name, path: '/notes/' + notes.MarkdownTemplate.name, component: notes.MarkdownTemplate },

        // Default (if invalid route entered)
        { name: 'default', path: '*', component: sketches.SceneTemplate }
    ]
})