//
//
//
//
//
//

import LightShadeShadow from './Markdown/Light-Shadow-Shade.md';
import 'highlight.js/styles/github-gist.css';

export default {
  // Tab name
  name: 'light-shade-shadow',
  components: { LightShadeShadow }
}
